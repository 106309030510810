<template>
  <div class="bill-print" ref="billitem">
    <div class="bill-main">
      <h6 class="bill-main--title">欢迎光临{{data.shopName}}</h6>
      <ul class="bill-info">
        <li class="bill-info__item">
          <span class="bill-info__item-title">交易编号:</span>{{data.orderId}}
        </li>
        <li class="bill-info__item">
          <span class="bill-info__item-title">交易时间:</span>{{data.orderTime}}
        </li>
      </ul>
      <div class="bill-goods__list line">
        <table>
          <tr>
            <th>商品名称</th>
            <th>数量</th>
            <th>单价</th>
            <th>小计</th>
          </tr>
          <tr v-for="(item, index) in data.unionList" :key="index+item.mId">
            <td>
              <div style="width: 100px;" class="text-ellipsis">{{item.name}}</div>
            </td>
            <td>x{{item.quantity}}</td>
            <td>{{item.price}}</td>
            <td>{{item.quantity * item.price}}</td>
          </tr>
        </table>
        <ul class="bill-info line">
          <li class="bill-info__item">
            <span class="bill-info__item-title">合计:</span> <span>{{data.amount}} </span>RM
          </li>
          <li class="bill-info__item">
            <span class="bill-info__item-title">优惠:</span> <span>{{data.amountDiscount}} </span>RM
          </li>
          <li class="bill-info__item">
<!--            <span class="bill-info__item-title">实收(<span>{{data.payItems&&data.payItems[0].payMethodName}}</span>):</span><span>{{data.amountPay}}</span>RM-->
            <span class="bill-info__item-title">实收:</span><span>{{data.amountPay}}</span>RM
          </li>
<!--          <li class="bill-info__item">-->
<!--            支付方式:-->
<!--            <template v-for="item in data.payItems">-->
<!--              {{item.payMethodName}}{{item.amountPaid}} 元-->
<!--            </template>-->
<!--            &lt;!&ndash;支付宝:77.00元 现金:200.00元&ndash;&gt;-->
<!--          </li>-->
        </ul>
        <!--<ul class="bill-info line">-->
          <!--<li class="bill-info__item">-->
            <!--<span class="bill-info__item-title">收银员:</span>梁朝伟-->
          <!--</li>-->
          <!--<li class="bill-info__item">-->
            <!--<span class="bill-info__item-title">门店电话:</span>010-56101234-->
          <!--</li>-->
          <!--<li class="bill-info__item">-->
            <!--<span class="bill-info__item-title">门店地址:</span>北京市朝阳区23号北京市朝阳区23号北京市朝阳区23号-->
          <!--</li>-->
        <!--</ul>-->
        <div class="bill-info__tips line">
          <div>谢谢惠顾，欢迎下次光临!</div>
<!--          <div>此小票一个月内开具发票有效！</div>-->
        </div>
      </div>
    </div>
    <div v-for="(item, pindex) in data.orderPlayItems" :key="item.orderKartPlayId">
      <div class="page-break"></div>
      <div class="bill-sub">
        <div class="bill-queue">
          <div>{{item.playSn}}</div>
          <div>Voucher</div>
          <div class="bill-qr">
            <img class="bill-qr-img" :src="item.qrCode" alt="">
          </div>
        </div>
        <div class="line">
          <div class="bill-sub__item">
            <div>Product Name:{{item.kartClassifyName}}</div>
            <!--<div>{{item.kartClassifyName}}</div>-->
          </div>
<!--          <div class="bill-sub__item">-->
<!--            <div>Quantity:X {{item.quantity}}</div>-->
<!--            &lt;!&ndash;<div>X{{item.quantity}}</div>&ndash;&gt;-->
<!--          </div>-->
        </div>
        <div class="line">
          <template v-if="storeInfo.groupNum > 1">
            <p></p>
            <p style="font-size: 18px;">Queue Information：Group {{item.groupNum}} - Number {{item.indexNum}} </p>
          </template>
          <template v-else>There is {{(queueCount - 1) + pindex}} person ahead of you. Please pay attention to your number being called. If you miss your turn, you will need to requeue!</template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrintJs from '@/libs/print';
import { orderInfo, queryPlayQueue } from '@/api/order';
import {
  wxUrl,
} from '@/libs/config';
import QRCode from 'qrcode';
import { getLocal } from '../../libs/utils';

export default {
  name: 'OrderBill',
  components: {},
  props: {},
  data() {
    return {
      data: {
        storeInfo: {},
        queueCount: 0
      }
    };
  },
  computed: {},
  watch: {},
  created() {
    this.storeInfo = getLocal('storeInfo');
  },
  beforeUpdate() {
    // this.getQueue();
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    getQueueCount(total) {
      return Math.ceil(total / this.storeInfo.groupNum);
    },
    getQueue() {
      const params = {
        pageIndex: 1,
        pageSize: 1,
      };
      queryPlayQueue(params).then((data) => {
        const { totalCount } = data;
        this.queueCount = totalCount;
      });
    },
    handlePrintBill(orderId) {
      const params = {
        pageIndex: 1,
        pageSize: 1,
      };
      queryPlayQueue(params).then((data) => {
        const { totalCount } = data;
        this.queueCount = totalCount;
        orderInfo(orderId).then((res) => {
          const { orderItems, productItems } = res;
          orderItems && orderItems.forEach((item) => {
            item.mId = item.kartClassifyId;
            item.name = item.kartClassifyName;
          });
          productItems && productItems.forEach((item) => {
            item.mId = item.productId;
            item.price = item.salePrice;
          });
          res.unionList = [...orderItems, ...productItems];
          this.data = res;
          this.generateQRCode();
          this.$nextTick(() => {
            PrintJs(this.$refs.billitem);
          });
        });
      });
    },
    generateQRCode() {
      const self = this;
      const storeInfo = getLocal('storeInfo');
      const isWeAppShop = storeInfo.wxMiniAppFlag;

      this.data.orderPlayItems.forEach((item, index) => {

        let codeData = wxUrl + item.orderKartPlayId;

        // 需要跳转到小程序到门店
        if (isWeAppShop) {
          const query = `?shopId=${storeInfo.shopId}&shopName=${storeInfo.shopName}&playCode=`;
          codeData = storeInfo.wxAppPath + query + item.orderKartPlayId;
          console.log(codeData);
        }

        QRCode.toDataURL(codeData, function (error, data) {
          if (error) {
            this.$message.error(error.toString);
            throw error;
          }
          item.qrCode = data;
          self.$set(self.data.orderPlayItems, index, item);
        });
      });
    }
  },
};
</script>

<style lang="less" scoped>

.bill-print {
  display: none;
}
@media print {
  .bill-print {
    display: block;
  }
}

@page {
  /* 纵向打印 */
  size: portrait;
  margin: 0;
  padding: 1px;
  margin-left: 0cm;
  margin-bottom: 5cm;
}

.page-break {
  page-break-after: always;
}

.bill-print {
  font-size: 12px;
}
.bill-info {
  color: #000;
  list-style: none;
}
.bill-info__item {
}
.bill-info__item-title {
  word-break: break-word;
  width: 200px;
}

.line:before {
  content: '';
  display: block;
  margin-top: 10px;
  border: 1px dashed #000;
}
.bill-queue {
  width: 200px;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
}
.bill-qr {
  margin-top: 10px;
  text-align: center;
}

.bill-qr-img {
  width: 200px;
  height: 200px;
}
.bill-sub__item {
  display: flex;
  justify-content: space-between;
}
.bill-main,
.bill-main--title,
table,
table th,
table td,
.bill-sub,
.bill-info__tips {
  font-size: 12px;
  color: #000;
}
</style>
